import React from 'react'

import { useNavigate } from 'react-router-dom';
import { SellerNav } from './SellerNav';
export const Thankyou = () => {
  return (
    <section class="car-details">
   <SellerNav />
    <div class="thankyou-page">
        <div class="inner">
            <h2>Congrats!!!</h2>
            <p>Your car is uploaded for sale.</p>
        </div>
    </div>
    </section>
  )
}
