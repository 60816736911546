import React, { useState, useEffect } from 'react';
import { SellerNav } from './SellerNav';
import api from '../api';
import { useNavigate } from 'react-router-dom';
export const LiveInAuction = () => {
const navigate = useNavigate();
const [posts, setPosts] = useState([]);
const [error, setError] = useState(null);
const storedUserId = sessionStorage.getItem('user_id');
const [isNewUser, setIsNewUser] = useState(false);
const [searchQuery, setSearchQuery] = useState('');
const [loading, setLoading] = useState(true); // Add a loading state
const [minutesMap, setMinutesMap] = useState({});
useEffect(() => {
sessionStorage.removeItem('isNewUser');
setIsNewUser(false);
const fetchPosts = async () => {
try {
if (!storedUserId) {
navigate('/sellerin');
return; // Stop further execution
}        
const response = await api.get(`/vehiclelisting/${storedUserId}/Seller/LiveInAuction`);
const { message, Vehicle } = response.data;
// Check if message is true and Vehicle is an array
if (message && Array.isArray(Vehicle)) {
// Log the entire response data
// Set the Vehicle data in state
setLoading(false); 
setPosts(Vehicle);
Vehicle.forEach((vehicle) => {
   fetchAuctionTimeout(vehicle.Vehicle_Id);
 });
} else {
throw new Error('Invalid data format: Vehicle array not found');
setLoading(true); 
}
} catch (error) {
   setLoading(true); 
setError(error.message);
}
};
fetchPosts();
}, [navigate, storedUserId]);
const fetchSerchdata = async () => {
try {
const response = await api.post(`/vehiclesearch/${storedUserId}/Seller`, {
search: searchQuery
});
const vehicles = response.data.Vehicle;
setPosts(vehicles);
} 
catch (error) {
console.error('Error fetching data:', error);
}
};
const handleSearchChange = (event) => {
setSearchQuery(event.target.value);
fetchSerchdata();
};
useEffect(() => {
   fetchAuctionTimeout();
 }, []);
 const fetchAuctionTimeout = async (vehicleId) => {
   try {
     const response = await api.get(`/timeoutdetails/${vehicleId}`);
     console.log(`API Response for Vehicle ${vehicleId}:`, response.data);
 
     setMinutesMap((prevState) => ({
       ...prevState,
       [vehicleId]: response.data.counterminutes, // Store minutes for this vehicle
     }));
   } catch (error) {
     console.error(`Error fetching auction timeout for Vehicle ${vehicleId}:`, error);
   }
 };
return (
<section className="car-details">
   <SellerNav />
   <div className="container">
      <div className="view-post-panel mid-table mt-4">
         <h3 className="main-heading py-3">View Posts</h3>
         <div className="car-bid-gallary">
            <form onSubmit={(e) => e.preventDefault()} className="input-group">
               <button type="submit"><i className="fa-solid fa-magnifying-glass"></i></button>
               <input
                  type="search"
                  id="form1"
                  className="form-control"
                  placeholder="Search Cars"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  />
            </form>
            <div className="carbid-gallery-panel py-4">
                <div className="row">
                  {loading ? (
                    <p>Loading...</p> // Render loading state while fetching data
                  ) : posts.length === 0 ? (
                    <div className="col-md-12">
                      <p className="text-center text-danger">No Car in Live Auction</p>
                    </div>
                  ) : (
                    posts.map((post) => (
                      <div key={post.Vehicle_Id} className="col-lg-4 col-12">
                        <a href={`/live-auction-car/${post.Vehicle_Id}`}>
                          <div className="carbid-image-panel">
                            {post.Exterior_Image && (
                              <img
                                src={`https://backend.carchaser.ca/uploads/${post.Vehicle_Id}/${post.Exterior_Image}`}
                                alt="Car"
                              />
                            )}
                            {post.Exterior_Image2 && !post.Exterior_Image && (
                              <img
                                src={`https://backend.carchaser.ca/uploads/${post.Vehicle_Id}/${post.Exterior_Image2}`}
                                alt="Car"
                              />
                            )}
                            {post.Exterior_Image3 &&
                              !post.Exterior_Image2 &&
                              !post.Exterior_Image && (
                                <img
                                  src={`https://backend.carchaser.ca/uploads/${post.Vehicle_Id}/${post.Exterior_Image3}`}
                                  alt="Car"
                                />
                              )}
                            {post.Exterior_Image4 &&
                              !post.Exterior_Image3 &&
                              !post.Exterior_Image2 &&
                              !post.Exterior_Image && (
                                <img
                                  src={`https://backend.carchaser.ca/uploads/${post.Vehicle_Id}/${post.Exterior_Image4}`}
                                  alt="Car"
                                />
                              )}
                            <div className="bidpanel-innercont">
                              <h6 className="pt-2">
                                {post.Year} {post.Make} {post.Model}{" "}
                              </h6>
                              <span>
                                <small>Vin #{post.VIN}</small>
                              </span>

                              {/* Display the auction timeout for this vehicle */}
                              <p className="text-white">
                                Auction TimeLeft:{" "}
                                {minutesMap[post.Vehicle_Id] !== undefined
                                  ? `${minutesMap[post.Vehicle_Id]} minutes`
                                  : "Loading..."}
                              </p>
                            </div>
                          </div>
                        </a>
                      </div>
                    ))
                  )}
                </div>
            </div>
         </div>
      </div>
   </div>
</section>
);
};