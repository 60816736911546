import React, { useState } from 'react';
import { useParams } from 'react-router-dom'; // Extract route parameters
import { SellerNav } from './SellerNav';
import api from '../api';

export const BidPopUp = () => {
  // Extract route parameters from the URL
  const { Vehicle_Id, Bid_Id1, Dealer_Id1, Bid_Id2, Dealer_Id2 } = useParams(); 
  const [counterPrice, setCounterPrice] = useState('');
  const [comments, setComments] = useState('');
  const sellerId = sessionStorage.getItem('user_id'); // Assuming seller ID is stored in session

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the page from reloading

    // Conditionally concatenate Bid_IDs and Dealer_IDs based on availability
    const bidIds = Bid_Id2 ? `${Bid_Id1},${Bid_Id2}` : Bid_Id1;
    const dealerIds = Dealer_Id2 ? `${Dealer_Id1},${Dealer_Id2}` : Dealer_Id1;

    const data = {
      BidID: bidIds, // Concatenating only available Bid_IDs
      VehicleID: parseInt(Vehicle_Id), // Vehicle ID from route params
      DealerID: dealerIds, // Concatenating only available Dealer_IDs
      SellerID: sellerId,
      CounterPrice: parseFloat(counterPrice.replace(/[$,]/g, '')), // Convert to a number
      Comments: comments,
      Action: "new",
      PortalName: "Seller"
    };

    try {
      const response = await api.post('/negotiation', data);
      console.log('API Response:', response.data);
      alert('Negotiation submitted successfully');
    } catch (error) {
      console.error('Error submitting negotiation:', error);
      alert('Error submitting negotiation');
    }
  };

  return (
    <section className="car-details">
      <SellerNav />
      <div className="container">
        <div className="nago-mainwrapper">
          <div className="nagotiate-popup">
            <h5>Negotiate Amount</h5>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                value={counterPrice}
                onChange={(e) => setCounterPrice(e.target.value)} // Update state
              />
            </div>
            <h5>Comments</h5>
            <div className="mb-3">
              <textarea
                className="form-control"
                rows="5"
                placeholder="Comments"
                value={comments}
                onChange={(e) => setComments(e.target.value)} // Update state
              ></textarea>
            </div>
            <div className="text-center nego-btn mt-4">
              <button className="btn btn-primary" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
