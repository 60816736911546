import React, { useRef, useState , useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SellerNav } from './SellerNav';
import api from '../api';
export const UploadVehicleDetails = () => {
const navigate = useNavigate();
const [isChecked, setIsChecked] = useState(false);
const [other, setOther] = useState('');
const [otherint, setIntOther] = useState('');
const [dashboardImageUrls, setDashboardImageUrls] = useState([]);
const [imageChangedDashboard, setImageChangedDashboard] = useState(Array(4).fill(false)); // Assuming 4 images, adjust the size accordingly
const [dashboardImages, setDashboardImages] = useState([]);
const [exteriorImageUrls, setExteriorImageUrls] = useState([]);
const [imageChangedExterior, setImageChangedExterior] = useState(Array(4).fill(false)); // Assuming 4 images, adjust the size accordingly
const [exteriorImages, setExteriorImages] = useState([]);
const [interiorImageUrls, setInteriorImageUrls] = useState([]);
const [imageChanged, setImageChanged] = useState(Array(4).fill(false)); // Assuming 4 images, adjust the size accordingly
const [interiorImages, setInteriorImages] = useState([]);
const [rimsImageUrls, setRimsImageUrls] = useState([]);
const [imageChangedRims, setImageChangedRims] = useState(Array(4).fill(false)); // Assuming 4 images, adjust the size accordingly
const [rimsImages, setRimsImages] = useState([]);
const [imagesData, setImagesData] = useState({});
const [isAfterMarketSelected, setIsAfterMarketSelected] = useState(false);
const [currentStep, setCurrentStep] = useState(1);
const stepRefs = useRef([]);
const [isInputVisible, setIsInputVisible] = useState(false);
const [hasIssues, setHasIssues] = useState(false);
const [isModificationsSelected, setIsModificationsSelected] = useState(false);
const [isfinancedSelected, setIsFinancedSelected] = useState(false);
const inputRefs = useRef([]);
const [imagePreviews, setImagePreviews] = useState(['', '', '', '']);
const [storedUserId, setStoredUserId] = useState(null); // Define storedUserId here
const [vehicleDetails, setVehicleDetails] = useState(null);
const [location, setLocation] = useState('');
const [vin, setVin] = useState('');
const [year, setYear] = useState('');
const [make, setMake] = useState('');
const [model, setModel] = useState('');
const [trim, setTrim] = useState('');
const [mileage, setMileage] = useState('');
const [selectedColor, setSelectedColor] = useState('');
const [selectedKeys, setselectedKeys] = useState('');
const [setoftire, setSetoftire] = useState(false);
const [windowTint, setWindowTint] = useState(false); 
const [aftermarketExhaust, setAftermarketExhaust] = useState(false);
const [aftermarketRims, setAftermarketRims] = useState(false);
const [roofRack, setRoofRack] = useState(false); 
const [remoteStarter, setRemoteStarter] = useState(false); 
const [aftermarketStereo, setAftermarketStereo] = useState(false);
const [aftermarketSpoiler, setAftermarketSpoiler] = useState(false); 
const [minorDamage, setMinorDamage] = useState(false);
const [fadingPaints, setFadingPaints] = useState(false);
const [rust, setRust] = useState(false); 
const [hailDamage, setHailDamage] = useState(false);
const [mintCondition, setMintCondition] = useState(false); 
const [dents, setDents] = useState(false);
const [ripsOrTears, setRipsOrTears] = useState(false); 
const [visibleStain, setVisibleStain] = useState(false);
const [strongSmell, setStrongSmell] = useState(false);
const [damagedSystems, setDamagedSystems] = useState(false);
const [IntmintCondition, setIntMintCondition] = useState(false);
const [hasOriginalRims, setHasOriginalRims] = useState(null);
const [smokeInVehicle, setSmokeInVehicle] = useState('');
const [tireReplacement, setTireReplacement] = useState(null); 
const [vehicleDrivable, setVehicleDrivable] = useState(null);
const [crackOnWindshield, setCrackOnWindshield] = useState(null);
const [extendedWarranty, setExtendedWarranty] = useState(null);
const [rimschange, setRimsChange] = useState('');
const [tradeInInterest, setTradeInInterest] = useState(null);
const [transmission, setTransmission] = useState(null);
const [hasWinterTires, setHasWinterTires] = useState(null); 
const [carCondition, setCarCondition] = useState('');
const [carfeatures, SetCarFeatures] = useState('');
const [sellTiming, setSellTiming] = useState(''); 
const [DonotNeedCar, setDonotNeedCar] = useState(''); 
const [MechElectIssues, setMechElectIssues] = useState(''); 
const [DownSize, setDownSize] = useState(''); 
const [BuyAnotherCar, setBuyAnotherCar] = useState(''); 
const [claimnote, setclaimnote] = useState(''); 
const [storedVehicleId, setStoredVehicleId] = useState(null); // Define storedVehicleId here
const [issuenote, setIssueNote] = useState('');
const [modificationsnote, setModificationsNote] = useState('');
const [financednote, setFinancedNote] = useState('');
const [notes, setNotes] = useState('');
const [notedrivable, setNoteDrivable] = useState('');
const [noteother, setNoteOther] = useState(''); 
const [noteIntother, setNoteIntOther] = useState(''); 
const [isNewUser, setIsNewUser] = useState(false);
const [isPopupVisible, setIsPopupVisible] = useState(false);
const [successMessage, setSuccessMessage] = useState('');
const [isLoading, setIsLoading] = useState(false);
const [interioimage, setInterioimage] = useState('');
const [vehiclevideo, setVehicleVideo] = useState('');
const [vehileprice, setCarPrice] = useState('');
const [getPrice, setGetPrice] = useState('');
const [averageprice, setGetaveragePrice] = useState('');
const [belowprice, setGetbelowPrice] = useState('');
const [selectedFile, setSelectedFile] = useState(null);
const [vechiclevideo, setVechicleVideo] = useState('');
const [transmissiontype, setTransmissionType] = useState('');
const [isVideoUploaded, setIsVideoUploaded] = useState(false);
const [showErrorMessage, setShowErrorMessage] = useState(true);
const [ownershipFile, setOwnershipFile] = useState(null);
const [drivingLicenseFile, setDrivingLicenseFile] = useState(null);
const [hasCarFax, setHasCarFax] = useState(null); // To track if seller has a Carfax report
const [selectedFileCar, setSelectedFileCar] = useState(null); 
const [messagecarfaxreport, setSuccessMessageCarFaxReport] = useState(''); 
const [transactionComplete, setTransactionComplete] = useState(false);
const [selectedFeatures, setSelectedFeatures] = useState([]);
const [pdfFilename, setPdfFilename] = useState(null);
const [pdfDownloadStatus, setPdfDownloadStatus] = useState(false);


const handleFeatureChange = (event) => {
   const feature = event.target.value;
   setSelectedFeatures((prevSelectedFeatures) => 
     prevSelectedFeatures.includes(feature)
       ? prevSelectedFeatures.filter((item) => item !== feature) // Remove if already selected
       : [...prevSelectedFeatures, feature] // Add if not selected
   );
 };
const handleOwnershipChange = (e) => {
   const file = e.target.files[0];
   setOwnershipFile(file);
   // Additional logic for file validation, if needed
 };
 
 const handleDrivingLicenseChange = (e) => {
   const file = e.target.files[0];
   setDrivingLicenseFile(file);
   // Additional logic for file validation, if needed
 };
const [imageTypes, setImageTypes] = useState(Array(dashboardImageUrls.length).fill(''));
// Validate if all uploaded images have a radio selected
const isRadioValid = dashboardImageUrls.every((imageUrl, index) => {
   // Only validate for uploaded images
   return !imageUrl || (imageTypes[index] === 'Exterior' || imageTypes[index] === 'Interior');
 });
 const isFormValid = isRadioValid && dashboardImageUrls.some(imageUrl => imageUrl);
 const handleRadioChange = (index, value) => {
   setImageTypes((prev) => {
     const updatedTypes = [...prev];
     updatedTypes[index] = value; // Update the specific index with the new type (Exterior/Interior)
     return updatedTypes;
   });
 };
// Modify handleImageChange to update the imageChanged state
const handleVideoChange = (e) => {
   const file = e.target.files[0]; // Get the selected file
   setSelectedFile(file); // Store the selected file in state
   if (file) {
      setVehicleVideo(file.name); // Set the video name or path
      setIsVideoUploaded(true); // Video is uploaded
      setShowErrorMessage(false); // Hide the error message when video is selected
    }
};
const handleImageChange = (event, index) => {
const file = event.target.files[0];
// Check if the user selected a new image
if (file) {
// User selected a new image, update the interiorImageUrls and set imageChanged to true
const reader = new FileReader();
reader.onloadend = () => {
const imageDataUrl = reader.result;
setInteriorImageUrls(prevImageUrls => {
const updatedImageUrls = [...prevImageUrls];
updatedImageUrls[index] = imageDataUrl;
return updatedImageUrls;
});
setImageChanged(prevImageChanged => {
const updatedImageChanged = [...prevImageChanged];
updatedImageChanged[index] = true;
return updatedImageChanged;
});
};
reader.readAsDataURL(file);
} else {
// User didn't select a new image, set imageChanged to false
setImageChanged(prevImageChanged => {
const updatedImageChanged = [...prevImageChanged];
updatedImageChanged[index] = false;
return updatedImageChanged;
});
}
};
// Dashboard
const handleImageChangedashboard = (event, index) => {
const file = event.target.files[0];
// Check if the user selected a new image
if (file) {
// User selected a new image, update the interiorImageUrls and set imageChanged to true
const reader = new FileReader();
reader.onloadend = () => {
const imageDataUrl = reader.result;
setDashboardImageUrls(prevImageUrls => {
const updatedImageUrls = [...prevImageUrls];
updatedImageUrls[index] = imageDataUrl;
return updatedImageUrls;
});
setImageChangedDashboard(prevImageChanged => {
const updatedImageChanged = [...prevImageChanged];
updatedImageChanged[index] = true;
return updatedImageChanged;
});
};
reader.readAsDataURL(file);
} else {
// User didn't select a new image, set imageChanged to false
setImageChangedDashboard(prevImageChanged => {
const updatedImageChanged = [...prevImageChanged];
updatedImageChanged[index] = false;
return updatedImageChanged;
});
}
};
// Exterior
const handleImageChangeexterior = (event, index) => {
const file = event.target.files[0];
// Check if the user selected a new image
if (file) {
// User selected a new image, update the interiorImageUrls and set imageChanged to true
const reader = new FileReader();
reader.onloadend = () => {
const imageDataUrl = reader.result;
setExteriorImageUrls(prevImageUrls => {
const updatedImageUrls = [...prevImageUrls];
updatedImageUrls[index] = imageDataUrl;
return updatedImageUrls;
});
setImageChangedExterior(prevImageChanged => {
const updatedImageChanged = [...prevImageChanged];
updatedImageChanged[index] = true;
return updatedImageChanged;
});
};
reader.readAsDataURL(file);
} else {
// User didn't select a new image, set imageChanged to false
setImageChangedExterior(prevImageChanged => {
const updatedImageChanged = [...prevImageChanged];
updatedImageChanged[index] = false;
return updatedImageChanged;
});
}
};
// Rims
const handleImageChangerims = (event, index) => {
const file = event.target.files[0];
// Check if the user selected a new image
if (file) {
// User selected a new image, update the interiorImageUrls and set imageChanged to true
const reader = new FileReader();
reader.onloadend = () => {
const imageDataUrl = reader.result;
setRimsImageUrls(prevImageUrls => {
const updatedImageUrls = [...prevImageUrls];
updatedImageUrls[index] = imageDataUrl;
return updatedImageUrls;
});
setImageChangedRims(prevImageChanged => {
const updatedImageChanged = [...prevImageChanged];
updatedImageChanged[index] = true;
return updatedImageChanged;
});
};
reader.readAsDataURL(file);
} else {
// User didn't select a new image, set imageChanged to false
setImageChangedRims(prevImageChanged => {
const updatedImageChanged = [...prevImageChanged];
updatedImageChanged[index] = false;
return updatedImageChanged;
});
}
};
const getFileNameFromInput = (event) => {
const input = event.target;
if (input.files && input.files.length > 0) {
return input.files[0].name;
}
return null;
};
// Interior
useEffect(() => {
const fetchDataAndConvertToBlob = async () => {
const blobPromises = interiorImageUrls.map(async (imageUrl, index) => {
if (imageUrl && !interiorImages[index]) {
const blob = await fetchAndConvertToBlob(imageUrl);
if (blob) {
setInteriorImages(prevImages => {
const updatedImages = [...prevImages];
updatedImages[index] = blob;
return updatedImages;
});
}
}
});
await Promise.all(blobPromises);
};
fetchDataAndConvertToBlob();
}, [interiorImageUrls]);
// Dashboard
useEffect(() => {
const fetchDataAndConvertToBlobDashboard = async () => {
const blobPromises = dashboardImageUrls.map(async (imageUrl, index) => {
if (imageUrl && !dashboardImages[index]) {
const blob = await fetchAndConvertToBlob(imageUrl);
if (blob) {
setDashboardImages(prevImages => {
const updatedImages = [...prevImages];
updatedImages[index] = blob;
return updatedImages;
});
}
}
});
await Promise.all(blobPromises);
};
fetchDataAndConvertToBlobDashboard();
}, [dashboardImageUrls]);
// Exterior
useEffect(() => {
const fetchDataAndConvertToBlobExterior = async () => {
const blobPromises = exteriorImageUrls.map(async (imageUrl, index) => {
if (imageUrl && !exteriorImages[index]) {
const blob = await fetchAndConvertToBlob(imageUrl);
if (blob) {
setExteriorImages(prevImages => {
const updatedImages = [...prevImages];
updatedImages[index] = blob;
return updatedImages;
});
}
}
});
await Promise.all(blobPromises);
};
fetchDataAndConvertToBlobExterior();
}, [exteriorImageUrls]);
// Rims
useEffect(() => {
const fetchDataAndConvertToBlobRims = async () => {
const blobPromises = rimsImageUrls.map(async (imageUrl, index) => {
if (imageUrl && !rimsImages[index]) {
const blob = await fetchAndConvertToBlob(imageUrl);
if (blob) {
setRimsImages(prevImages => {
const updatedImages = [...prevImages];
updatedImages[index] = blob;
return updatedImages;
});
}
}
});
await Promise.all(blobPromises);
};
fetchDataAndConvertToBlobRims();
}, [rimsImageUrls]);
const fetchAndConvertToBlob = async (imageUrl) => {
try {
const response = await fetch(imageUrl);
const blob = await response.blob();
return blob;
} catch (error) {
console.error('Error fetching image:', error);
return null;
}
};
const dataURItoBlob = (dataURI) => {
const byteString = atob(dataURI.split(',')[1]);
const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
const ab = new ArrayBuffer(byteString.length);
const ia = new Uint8Array(ab);
for (let i = 0; i < byteString.length; i++) {
ia[i] = byteString.charCodeAt(i);
}
return new Blob([ab], { type: mimeString });
};
const handleColorChange = (event) => {
setSelectedColor(event.target.value);
};
const handlekeys = (event) => {
setselectedKeys(event.target.value);
};
const handleSetoftireChange = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
setSetoftire(isChecked); // Update state based on checkbox checked status
};
const handleWindowTintChange = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
setWindowTint(isChecked); // Update state based on checkbox checked status
};
const handleAftermarketExhaustChange = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
setAftermarketExhaust(isChecked); // Update state based on checkbox checked status
};
const handleAftermarketRimsChange = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
setAftermarketRims(isChecked); // Update state based on checkbox checked status
};
const handleRoofRackChange = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
setRoofRack(isChecked); // Update state based on checkbox checked status
};
const handleRemoteStarterChange = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
setRemoteStarter(isChecked); // Update state based on checkbox checked status
};
const handleAftermarketStereoChange = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
setAftermarketStereo(isChecked); // Update state based on checkbox checked status
};
const handleAftermarketSpoilerChange = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
setAftermarketSpoiler(isChecked); // Update state based on checkbox checked status
};
const handleMinorDamageChange = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
if (isChecked && mintCondition) {
setMintCondition(false); // Uncheck "mint condition" if it's checked
}
setMinorDamage(isChecked); // Update state based on checkbox checked status
};
const handleFadingPaintsChange = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
if (isChecked && mintCondition) {
setMintCondition(false); // Uncheck "mint condition" if it's checked
}
setFadingPaints(isChecked); // Update state based on checkbox checked status
};
const handleRustChange = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
if (isChecked && mintCondition) {
setMintCondition(false); // Uncheck "mint condition" if it's checked
}
setRust(isChecked); // Update state based on checkbox checked status
};
const handleHailDamageChange = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
if (isChecked && mintCondition) {
setMintCondition(false); // Uncheck "mint condition" if it's checked
}
setHailDamage(isChecked); // Update state based on checkbox checked status
};
const handleMintConditionChange = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
if (isChecked) {
setMinorDamage(false);
setFadingPaints(false);
setRust(false);
setDents(false);
setHailDamage(false);
setOther(false);
}
setMintCondition(isChecked); // Update state based on checkbox checked status
};
const handleDentsChange = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
if (isChecked && mintCondition) {
setMintCondition(false); // Uncheck "mint condition" if it's checked
}
setDents(isChecked); // Update state based on checkbox checked status
};
const handleOtherChange = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
if (isChecked && mintCondition) {
setMintCondition(false); // Uncheck "mint condition" if it's checked
}
setOther(isChecked); // Update state based on checkbox checked status
};
const handleOtherIntChange = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
if (isChecked && IntmintCondition) {
   setIntMintCondition(false); // Uncheck "mint condition" if it's checked
}
setIntOther(isChecked); // Update state based on checkbox checked status
};
const handleRipsOrTearsChange = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
if (isChecked && IntmintCondition) {
setIntMintCondition(false); // Uncheck "mint condition" if it's checked
}
setRipsOrTears(isChecked); // Update state based on checkbox checked status
};


const handleVisibleStainChange = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
if (isChecked && IntmintCondition) {
setIntMintCondition(false); // Uncheck "mint condition" if it's checked
}
setVisibleStain(isChecked); // Update state based on checkbox checked status
};
const handleStrongSmellChange = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
if (isChecked && IntmintCondition) {
setIntMintCondition(false); // Uncheck "mint condition" if it's checked
}
setStrongSmell(isChecked); // Update state based on checkbox checked status
};
const handleDamagedSystemsChange = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
if (isChecked && IntmintCondition) {
setIntMintCondition(false); // Uncheck "mint condition" if it's checked
}
setDamagedSystems(isChecked); // Update state based on checkbox checked status
};
const handleIntMintConditionChange = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
// Uncheck all other checkboxes if "mint condition" is checked
if (isChecked) {
setRipsOrTears(false);
setVisibleStain(false);
setStrongSmell(false);
setDamagedSystems(false);
setIntOther(false);
}
setIntMintCondition(isChecked); // Update state based on checkbox checked status
};
const handleOriginalRimsChange = (event) => {
const value = event.target.value; // Get the selected value
setHasOriginalRims(value === 'Yes'); // Update state based on the selected value
};
const handleSmokeInVehicleChange = (event) => {
const value = event.target.value; // Get the selected value
setSmokeInVehicle(value === 'Yes'); // Update state based on the selected value
};
const handleTireReplacementChange = (event) => {
const value = event.target.value; // Get the selected value
setTireReplacement(value === 'Yes'); // Update state based on the selected value
};
const handleVehicleDrivableChange = (event) => {
const value = event.target.value; // Get the selected value
setVehicleDrivable(value === 'Yes'); // Update state based on the selected value
if (value === 'No') {
setNoteDrivable(''); // Clear the input field if the user selects "No"
}
};
const handleCrackOnWindshieldChange = (event) => {
const value = event.target.value; // Get the selected value
setCrackOnWindshield(value === 'Yes'); // Update state based on the selected value
};
const handleExtendedWarrantyChange = (event) => {
const value = event.target.value; // Get the selected value
setExtendedWarranty(value === 'Yes'); // Update state based on the selected value
};
const handleMarketRimsChange = (event) => {
const value = event.target.value; // Get the selected value
setRimsChange(value === 'Yes'); // Update state based on the selected value
};
const handleaccidentclaims = (event) => {
const value = event.target.value; // Get the selected value
setIsInputVisible(value === 'Yes'); // Update state based on the selected value
if (value === 'No') {
setclaimnote(''); // Clear the input field if the user selects "No"
}
};
const handleTradeInInterestChange = (event) => {
const value = event.target.value; // Get the selected value
setTradeInInterest(value === 'Yes'); // Update state based on the selected value
};

const handleTransmissionChange = (event) => {
   const value = event.target.value; // Get the selected value
   setTransmission(value); // Set the state directly to "Auto" or "Manual"
 };


const handleWinterTiresChange = (event) => {
const value = event.target.value; // Get the selected value
setHasWinterTires(value === 'Yes'); // Update state based on the selected value
};
const handleCarConditionChange = (event) => {
const value = event.target.value; // Get the selected value
setCarCondition(value); // Update state with the selected value
};
const handleCarfeaturesChange = (event) => {
   const value = event.target.value; // Get the selected value
   SetCarFeatures(value); // Update state with the selected value
   };
const handleSellTimingChange = (event) => {
const value = event.target.value; // Get the selected value
setSellTiming(value); // Update state with the selected value   in your API request
};
const handledonotneedCar = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
setDonotNeedCar(isChecked); // Update state based on checkbox checked status
};
const handlemechelectIssues = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
setMechElectIssues(isChecked); // Update state based on checkbox checked status
};
const handlesellingtodownsize = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
setDownSize(isChecked); // Update state based on checkbox checked status
};
const handleBuyAnotherCar = (event) => {
const isChecked = event.target.checked; // Check if the checkbox is checked
setBuyAnotherCar(isChecked); // Update state based on checkbox checked status
};
const handleclaimnote = (event) => {
const value = event.target.value; // Get the value from the textarea
setclaimnote(value); // Update state with the value from the textarea
};
const handleissuenote = (event) => {
const value = event.target.value; // Get the value from the textarea
setIssueNote(value); // Update state with the value from the textarea
};
const handlemodificationsnote = (event) => {
const value = event.target.value; // Get the value from the textarea
setModificationsNote(value); // Update state with the value from the textarea
};
const handlefinancednote = (event) => {
const value = event.target.value; // Get the value from the textarea
setFinancedNote(value); // Update state with the value from the textarea
};
const handlenote = (event) => {
   const value = event.target.value; // Get the value from the textarea
   setNotes(value); // Update state with the value from the textarea
   };
const handledrivablenote = (event) => {
const value = event.target.value; // Get the value from the textarea
setNoteDrivable(value); // Update state with the value from the textarea
};
const handleotherlenote = (event) => {
const value = event.target.value; // Get the value from the textarea
setNoteOther(value); // Update state with the value from the textarea
};

const handleotherleIntnote = (event) => {
const value = event.target.value; // Get the value from the textarea
setNoteIntOther(value); // Update state with the value from the textarea
};
const handlevehiclevideo = (event) => {
const value = event.target.value; // Get the value from the textarea
setVehicleVideo(value); // Update state with the value from the textarea
};
const fetchData = async () => {
try {
const storedVehicleId = sessionStorage.getItem('Vehicle_Id');
if (!storedVehicleId) {
return;
}
const response = await api.get(`/seller/vehicle/${storedVehicleId}`);
const vehicleData = response.data;
// Log the entire API response
setLocation(vehicleData.vehicle.Location || '');
setVin(vehicleData.vehicle.VIN || '');
setYear(vehicleData.vehicle.Year || '');
setModel(vehicleData.vehicle.Model || '');
setTrim(vehicleData.vehicle.Trim || '');
setMileage(vehicleData.vehicle.Mileage || '');
setMake(vehicleData.vehicle.Make || '');
setSelectedColor(vehicleData.vehicle.Color || '');
setselectedKeys(vehicleData.vehicle.Car_Keys || '');
setSetoftire(vehicleData.vehicle['2_Sets_Of_Tire'] || '');
setWindowTint(vehicleData.vehicle.Win_Tint || '');
setAftermarketExhaust(vehicleData.vehicle.Aftermark_Exhaust || '');
setAftermarketRims(vehicleData.vehicle.Aftermark_Rims || '');
setRoofRack(vehicleData.vehicle.Roof_Rack || '');
setRemoteStarter(vehicleData.vehicle.Remote_Start || '');
setAftermarketStereo(vehicleData.vehicle.Aftermark_Stereo || '');
setAftermarketSpoiler(vehicleData.vehicle.Aftermark_Spoiler || '');
setMinorDamage(vehicleData.vehicle.Minor_Damage || '');
setFadingPaints(vehicleData.vehicle.Fading_Paints || '');
setRust(vehicleData.vehicle.Rust || '');
setHailDamage(vehicleData.vehicle.Hail_Damage || '');
setMintCondition(vehicleData.vehicle.Ext_Mint_Condition || '');
setDents(vehicleData.vehicle.Dents || '');
setOther(vehicleData.vehicle.Other_Damage || '');
setIntOther(vehicleData.vehicle.Other_Interior_Damage || '');
setRipsOrTears(vehicleData.vehicle.Seats_Rips_Tears || '');
setVisibleStain(vehicleData.vehicle.Seats_Visible_Stain || '');
setStrongSmell(vehicleData.vehicle.Strong_Smell || '');
setDamagedSystems(vehicleData.vehicle.Nav_Entmt_Ctrl_Dmg_Sys || '');
setIntMintCondition(vehicleData.vehicle.Int_Mint_Condition || '');
setHasOriginalRims(vehicleData.vehicle.Original_Factory_Rims || '');
setSmokeInVehicle(vehicleData.vehicle.Smoke_In_Vehicle || '');
setTireReplacement(vehicleData.vehicle.Tires_Repld_12_Months || '');
setVehicleDrivable(vehicleData.vehicle.Vehicle_Drivable || '');
setCrackOnWindshield(vehicleData.vehicle.Windshield_Crack || '');
setExtendedWarranty(vehicleData.vehicle.Extended_Warranty || '');
setRimsChange(vehicleData.vehicle.Car_Rims || '');
setTradeInInterest(vehicleData.vehicle.TradeIn_Interested || '');
setHasWinterTires(vehicleData.vehicle.Winter_Tires || '');
setCarCondition(vehicleData.vehicle.Car_Condition || '');
setSellTiming(vehicleData.vehicle.How_Soon_Ready_Sell || '');
setDonotNeedCar(vehicleData.vehicle.Donot_Need_Car || '');
setMechElectIssues(vehicleData.vehicle.Mech_Elect_Issues || '');
setDownSize(vehicleData.vehicle.Downsize || '');
setBuyAnotherCar(vehicleData.vehicle.Buy_Another_Car || '');
setclaimnote(vehicleData.vehicle.How_Much_Claims || '');
setIssueNote(vehicleData.vehicle.Vehicle_Issues_desc || '');
setModificationsNote(vehicleData.vehicle.Modification_desc || '');
setFinancedNote(vehicleData.vehicle.Financed_By || '');
setNoteDrivable(vehicleData.vehicle.vehicle_drivable_not_explanation || '');
setNoteOther(vehicleData.vehicle.Other_Damage_Description || '');
setNoteIntOther(vehicleData.vehicle.Other_Interior_Damage_Description || '');
setIsInputVisible(vehicleData.vehicle.Accident_Claims || '');
setHasIssues(vehicleData.vehicle.Any_Vehicle_Issues || '');
setIsModificationsSelected(vehicleData.vehicle.Car_Modification || '');
setIsFinancedSelected(vehicleData.vehicle.Leased_Financed || '');
setIsAfterMarketSelected(vehicleData.vehicle.Car_Rims || '');
setRimsChange(vehicleData.vehicle.Stock_Rims || '');
setInterioimage(vehicleData.vehicle.Interior_Image || '');
setVehicleVideo(vehicleData.vehicle.Vehicle_video || '');
setVechicleVideo(vehicleData.vehicle['Vehicle_video'] ) 
setOwnershipFile(vehicleData.vehicle.Ownership_Doc);
setDrivingLicenseFile(vehicleData.vehicle.Driving_Licence_Doc);
setSelectedFeatures(vehicleData.vehicle.Additional_Car_Feature.split(', '));
setTransmissionType(vehicleData.vehicle.Transmission_Type) 
const vin = vehicleData.vehicle.VIN;
const year = vehicleData.vehicle.Year;
const model = vehicleData.vehicle.Model;
const trim = vehicleData.vehicle.Trim;
const mileage = vehicleData.vehicle.Mileage;
const make = vehicleData.vehicle.Make;
} catch (error) {
console.error('Error fetching vehicle details:', error);
}
};
const handleRemoveImage = (indexToRemove) => {
setInteriorImageUrls(prevUrls => {
const newUrls = [...prevUrls];
newUrls[indexToRemove] = null; // Set the image URL at the specified index to null
return newUrls;
});
};
const handleRemoveExteriorImage = (indexToRemove) => {
setExteriorImageUrls(prevUrls => {
const newUrls = [...prevUrls];
newUrls[indexToRemove] = null; // Set the image URL at the specified index to null
return newUrls;
});
};
const handleRemoveDashboardImage = (indexToRemove) => {
setDashboardImageUrls(prevUrls => {
const newUrls = [...prevUrls];
newUrls[indexToRemove] = null; // Set the image URL at the specified index to null
return newUrls;
});
};
const handleRemoveRimsImage = (indexToRemove) => {
setRimsImageUrls(prevUrls => {
const newUrls = [...prevUrls];
newUrls[indexToRemove] = null; // Set the image URL at the specified index to null
return newUrls;
});
};
useEffect(() => {
const storedUserId = sessionStorage.getItem('user_id');
if (!storedUserId) {
navigate('/sellerin');
} else {
const storedVehicleId = sessionStorage.getItem('Vehicle_Id');
console.log('Stored Vehicle Id:', storedVehicleId); // Log storedVehicleId
if (!storedVehicleId) {
// Handle case where Vehicle_Id is not stored
console.error('Vehicle Id not found in session storage');
} else {
setStoredUserId(storedUserId);
setStoredVehicleId(storedVehicleId); // Set storedVehicleId here
fetchData(storedVehicleId); // Call fetchData with storedVehicleId
}
}
}, [navigate]);
const getFileNameFromUrl = (url) => {
// Split the URL by '/' and get the last part
const parts = url.split('/');
return parts[parts.length - 1];
}; 
const handleSubmit = async (event) => {
   if (!ownershipFile || !drivingLicenseFile) {
      alert("Please upload both Ownership and Driving License files.");
      return;
    }
   if (!selectedFile && !vechiclevideo) {
      alert('Video is mandatory! Please upload a video before submit.');
      return;
    }
    const featuresString = selectedFeatures.join(', ');
event.preventDefault();
try {
const formData = new FormData(); // Create a new FormData object

formData.append('Location', location);
formData.append('VIN', vin);
formData.append('Year', year);
formData.append('Model', model);
formData.append('Trim', trim);
formData.append('Mileage', mileage);
formData.append('Make', make);
formData.append('Seller_Id', storedUserId);
formData.append('Color', selectedColor);
formData.append('Car_Keys', selectedKeys);
formData.append('2_Sets_Of_Tire', setoftire ? '1' : '0');
formData.append('Win_Tint', windowTint ? '1' : '0');
formData.append('Aftermark_Exhaust', aftermarketExhaust ? '1' : '0');
formData.append('Aftermark_Rims', aftermarketRims ? '1' : '0');
formData.append('Roof_Rack', roofRack ? '1' : '0');
formData.append('Remote_Start', remoteStarter ? '1' : '0');
formData.append('Aftermark_Stereo', aftermarketStereo ? '1' : '0');
formData.append('Aftermark_Spoiler', aftermarketSpoiler ? '1' : '0');
formData.append('Minor_Damage', minorDamage ? '1' : '0');
formData.append('Fading_Paints', fadingPaints ? '1' : '0');
formData.append('Rust', rust ? '1' : '0');
formData.append('Hail_Damage', hailDamage ? '1' : '0');
formData.append('Ext_Mint_Condition', mintCondition ? '1' : '0');
formData.append('Dents', dents ? '1' : '0');
formData.append('Seats_Rips_Tears', ripsOrTears ? '1' : '0');
formData.append('Seats_Visible_Stain', visibleStain ? '1' : '0');
formData.append('Strong_Smell', strongSmell ? '1' : '0');
formData.append('Nav_Entmt_Ctrl_Dmg_Sys', damagedSystems ? '1' : '0');
formData.append('Int_Mint_Condition', IntmintCondition ? '1' : '0');
formData.append('Original_Factory_Rims', hasOriginalRims ? '1' : '0');
formData.append('Smoke_In_Vehicle', smokeInVehicle ? '1' : '0');
formData.append('Tires_Repld_12_Months', tireReplacement ? '1' : '0');
formData.append('Vehicle_Drivable', vehicleDrivable ? '1' : '0');
formData.append('Windshield_Crack', crackOnWindshield ? '1' : '0');
formData.append('Extended_Warranty', extendedWarranty ? '1' : '0');
formData.append('TradeIn_Interested', tradeInInterest ? '1' : '0');
formData.append('transmission', transmission);
formData.append('Winter_Tires', hasWinterTires ? '1' : '0');
formData.append('Car_Condition', carCondition);
formData.append('How_Soon_Ready_Sell', sellTiming);
formData.append('Donot_Need_Car', DonotNeedCar ? '1' : '0');
formData.append('Mech_Elect_Issues', MechElectIssues ? '1' : '0');
formData.append('Downsize', DownSize ? '1' : '0');
formData.append('Buy_Another_Car', BuyAnotherCar ? '1' : '0');
formData.append('Accident_Claims', isInputVisible ? '1' : '0');
formData.append('How_Much_Claims', claimnote);
formData.append('Car_Rims', isAfterMarketSelected ? 'AfterMarket' : 'StockRims');
formData.append('Stock_Rims', rimschange ? '1' : '0');
formData.append('Any_Vehicle_Issues', hasIssues ? '1' : '0');
formData.append('Vehicle_Issues_desc', issuenote);
formData.append('Car_Modification', isModificationsSelected  ? '1' : '0');
formData.append('Modification_desc', modificationsnote);
formData.append('Leased_Financed', isfinancedSelected  ? '1' : '0');
formData.append('Financed_By', financednote);
formData.append('Car_Notes', notes);
formData.append('vehicle_drivable_not_explanation', notedrivable);
formData.append('Other_Damage', other  ? '1' : '0');
formData.append('Other_Damage_Description', noteother);
formData.append('Other_Interior_Damage', otherint  ? '1' : '0');
formData.append('Other_Interior_Damage_Description', noteIntother);
formData.append('ownership_doc', ownershipFile);
formData.append('driving_licence_doc', drivingLicenseFile);
formData.append('Additional_Car_Feature', featuresString);
if (selectedFileCar) {
   formData.append('evaluation_report', selectedFileCar); // Append selected file
}
// If no file is selected but pdfFilename is available, append the filename to formData
else if (pdfFilename) {
   formData.append('evaluation_report', pdfFilename); // Append PDF filename from the API
}

if (selectedFile) {
   formData.append('Vehicle_video', selectedFile); // Append new video if provided
} else if (!vechiclevideo) {
   alert('Video is mandatory! Please upload a video before submitting.');
   return; // Exit if no video is provided
} else {
   formData.append('Vehicle_video', vechiclevideo); // Append existing video if no new file
}
interiorImageUrls.forEach((imageUrl, index) => {
const fileInput = document.getElementById(`imageInput-${index}`);
const file = fileInput.files[0];
if (imageUrl && !imageChanged[index]) {
// Extract file name from the URL
const fileName = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
// Send the file name to your backend API
formData.append(`Interior_Image${index + 1}`, fileName);
} else if (file) {
// Send the image file to your backend API
formData.append(`Interior_Image${index + 1}`, file);
}
});
// Dashboard
dashboardImageUrls.forEach((imageUrl, index) => {
   const fileInput = document.getElementById(`imageInputd-${index}`);
   const file = fileInput.files[0];
 
   if (imageUrl && !imageChangedDashboard[index]) {
     // Extract file name from the URL if the image has not changed
     const fileName = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
 
     // Append the file name and image type (Interior/Exterior) to the FormData
     formData.append(`Dashboard_Image${index + 1}`, fileName);
     formData.append(`damage_image${index + 1}_type`, imageTypes[index]); // Append the image type
   } else if (file) {
     // If the image has changed, append the new image file and the image type
     formData.append(`Dashboard_Image${index + 1}`, file);
     formData.append(`damage_image${index + 1}_type`, imageTypes[index]); // Append the image type
   }
 });
// Exterior
exteriorImageUrls.forEach((imageUrl, index) => {
const fileInput = document.getElementById(`imageInpute-${index}`);
const file = fileInput.files[0];
if (imageUrl && !imageChangedExterior[index]) {
// Extract file name from the URL
const fileName = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
// Send the file name to your backend API
formData.append(`Exterior_Image${index + 1}`, fileName);
} else if (file) {
// Send the image file to your backend API
formData.append(`Exterior_Image${index + 1}`, file);
}
});
// Rims
rimsImageUrls.forEach((imageUrl, index) => {
const fileInput = document.getElementById(`imageInputr-${index}`);
const file = fileInput.files[0];
if (imageUrl && !imageChangedRims[index]) {
// Extract file name from the URL
const fileName = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
// Send the file name to your backend API
formData.append(`Rims_Image${index + 1}`, fileName);
} else if (file) {
// Send the image file to your backend API
formData.append(`Rims_Image${index + 1}`, file);
}
});
// Make the API request with formData
setIsLoading(true); // Show loader
setIsPopupVisible(false);  // Hide Popup
try {
const response = await api.post(`/seller/updatevehicle/${storedVehicleId}`, formData, {
headers: {
'Content-Type': 'multipart/form-data', // Set content type to multipart/form-data
},
});
setSuccessMessage('Vehicle Edit Successfuly');
} catch (error) {
console.error('Error uploading vehicle details:', error);
// Handle error
} finally {
if (!sessionStorage.getItem('isNewUser')) {
// Set a timeout to navigate to '/view-posts' after 5 seconds
setTimeout(() => {
// Navigate to '/view-posts' page
navigate('/thankyou');
}, 5000);
} else { 
// If 'isNewUser' key exists, remove it from session storage
sessionStorage.removeItem('isNewUser');
// Update state to reflect the change
setIsNewUser(false);
setIsLoading(false); // Hide loader
setIsPopupVisible(true); 
}
}
} catch (error) {
console.error('Error uploading vehicle details:', error);
// Handle error
}
};
const nextStep = () => {
setCurrentStep(prevStep => {
const nextStep = prevStep + 1;
// Scroll to the top of the next step
window.scrollTo({
top: stepRefs.current[nextStep - 1].offsetTop,
behavior: 'smooth'
});
return nextStep;
});
};
// Function to move to the previous step
const prevStep = () => {
setCurrentStep(prevStep => prevStep - 1);
};
// Function to update step classes based on currentStep
const updateStepClasses = () => {
// Remove 'active' class from all steps
stepRefs.current.forEach((stepRef, index) => {
stepRef.classList.remove('active');
});
// Add 'active' class to the current step
stepRefs.current[currentStep - 1].classList.add('active');
};
// Call updateStepClasses whenever currentStep changes
useEffect(() => {
const vehicleId = sessionStorage.getItem('Vehicle_Id');
if (!vehicleId) {
navigate('/live-auction');
}
const isNewUserSession = sessionStorage.getItem('isNewUser');
setIsNewUser(isNewUserSession === 'true');
updateStepClasses();
fetchDataImages();
}, [currentStep]);
const baseImageUrl = `https://backend.carchaser.ca/uploads/${storedVehicleId}/`;
const fetchDataImages = async () => {
try {
const response = await api.get(`/seller/getvehicleimages/${storedVehicleId}`);
// Extract image names from the response
const dashboardImages = response.data.Vehicle_Image[0].dashboard;
const exteriorImages = response.data.Vehicle_Image[0].exterior;
const interiorImages = response.data.Vehicle_Image[0].interior;
const rimsImages = response.data.Vehicle_Image[0].rims;
// Mix base URL with each image name and filter out null values
const mixBaseDashboardImages = [];
dashboardImages.forEach(image => {
if (image !== null) {
mixBaseDashboardImages.push(baseImageUrl + image);
} else {
mixBaseDashboardImages.push(null);
}
});
const mixBaseExteriorImages = [];
exteriorImages.forEach(image => {
if (image !== null) {
mixBaseExteriorImages.push(baseImageUrl + image);
} else {
mixBaseExteriorImages.push(null);
}
});
const mixBaseInteriorImages = [];
interiorImages.forEach(image => {
if (image !== null) {
mixBaseInteriorImages.push(baseImageUrl + image);
} else {
mixBaseInteriorImages.push(null);
}
});
const mixBaseRimsImages = [];
rimsImages.forEach(image => {
if (image !== null) {
mixBaseRimsImages.push(baseImageUrl + image);
} else {
mixBaseRimsImages.push(null);
}
});
setInteriorImageUrls(mixBaseInteriorImages);
setDashboardImageUrls(mixBaseDashboardImages);
setExteriorImageUrls(mixBaseExteriorImages);
setRimsImageUrls(mixBaseRimsImages);
} catch (error) {
console.error('Error fetching vehicle images:', error);
// Handle error
}
};
const renderStepIndicator = () => {
return (
<ul className="step-indicator py-3">
   {[...Array(2)].map((_, index) => (
   <li
   key={index}
   className={index + 1 === currentStep ? 'active' : ''}
   onClick={() => handleStepClick(index + 1)}
   >
   <img src="../images/Group 27.png" alt={`Step ${index + 1}`} />
   </li>
   ))}
</ul>
);
};
const handleStepClick = (step) => {
if (step < currentStep) {
setCurrentStep(step);
}
};
const handleOptionChangerimss = (event) => {
const value = event.target.value; // Get the selected value
setIsAfterMarketSelected(value === 'AfterMarket'); // Update state based on the selected value
if (value === 'StockRims') {
setRimsChange('');
}
};
const handleIssuesChange = (event) => {
const value = event.target.value; // Get the selected value
setHasIssues(value === 'Yes'); // Update state based on the selected value
if (value === 'No') {
setIssueNote('');
}
setHasIssues(event.target.id === 'showButton2');
};
const handleModificationsChange = (event) => {
const value = event.target.value; // Get the selected value
setIsModificationsSelected(value === 'Yes'); // Update state based on the selected value
if (value === 'No') {
setModificationsNote('');
}
setIsModificationsSelected(event.target.id === 'showButton3');
};
const handlefinanced = (event) => {
const value = event.target.value; // Get the selected value
setIsFinancedSelected(value === 'Yes'); // Update state based on the selected value
if (value === 'No') {
setFinancedNote('');
}
setIsFinancedSelected(event.target.id === 'showButton4');
};
const handleFileChange = (index, event) => {
const file = event.target.files[0];
if (file) {
// Check if the file type is supported (e.g., image/jpeg, image/png)
const allowedTypes = ['image/jpeg', 'image/png'];
if (!allowedTypes.includes(file.type)) {
// Handle unsupported file type error
return;
}
// Check if the file size exceeds the limit (in bytes)
const maxSize = 5 * 1024 * 1024; // 5 MB
if (file.size > maxSize) {
// Handle file size limit exceeded error
return;
}
const reader = new FileReader();
reader.onload = () => {
const newPreviews = [...imagePreviews];
newPreviews[index] = reader.result;
setImagePreviews(newPreviews);
};
reader.readAsDataURL(file);
} else {
// Handle no file selected error
console.error('No file selected.');
}
};
const handleCheckboxChange = () => {
setIsChecked(!isChecked);
};
let message = '';
let requiredImages = 0;
if (other && otherint) {
   message = 'Mandatory note: Upload at least one photo of any exterior damage and at least one photo of any interior damage. Thank you!';

  requiredImages = 2;
} else if (other) {
  

   message = 'Mandatory note: Upload at least one photo showing any exterior damage. Thank you!';
  requiredImages = 1;
} else if (otherint) {
   message = 'Mandatory note: Upload at least one photo that shows any interior damage. Thank you! ';
  
  requiredImages = 1;
}
const uploadedImages = dashboardImageUrls.filter((url) => url !== null).length;
const isUploadValid = dashboardImageUrls.filter(Boolean).length >= requiredImages;
const handleFileChangeCar = (e) => {
   const file = e.target.files[0];
   setSelectedFileCar(file); // Update the state with the selected file
};

const handleYesClick = () => {
   setHasCarFax(true); // Seller clicked Yes
};
const handleNoClick = () => {
   setHasCarFax(false); // Seller clicked No
};

useEffect(() => {
   // Only load PayPal script if 'No' is selected
   if (hasCarFax === false) {
       const script = document.createElement("script");
       script.src = `https://www.paypal.com/sdk/js?client-id=Aa5-E0q0hfQEoRcv6cbp76S0cIcSbmPW16LaZqSIdFKs0oN6zZ3bB5y-3UiFiKJra58aBnYWLFIZ-7SM&currency=USD&locale=en_US`;
       script.async = true;

       const loadPayPalButton = () => {
           if (window.paypal) {
               document.getElementById("paypal-button-container").innerHTML = "";

               window.paypal.Buttons({
                   createOrder: (data, actions) => {
                       return actions.order.create({
                           purchase_units: [{
                               amount: { value: '0.01' }, // Replace with your amount
                           }],
                           application_context: {
                               shipping_preference: "NO_SHIPPING", 
                               user_action: 'PAY_NOW',
                           }
                       });
                   },
                   onApprove: (data, actions) => {
                     return actions.order.capture().then(details => {
                         setSuccessMessageCarFaxReport(`Transaction completed by ${details.payer.name.given_name}`);
                         setTransactionComplete(true);
                         
                         // Call fetchCarfaxElvalutionApi to fetch the report after transaction completion
                         const fetchCarfaxElvalutionApi = async () => {
                             try {
                                 const response = await api.get(`/seller/carfaxevalvationreport/${storedVehicleId}/${vin}`);
                                 console.log(response.data); // Log or handle the fetched report data
                                 if (response.data.status) {
                                    setPdfFilename(response.data.filename);
                                    setPdfDownloadStatus(true); // Enable download button
                                }
                             } catch (error) {
                                 console.error("Error fetching PDF:", error);
                             }
                         };
                         
                         fetchCarfaxElvalutionApi(); // Initiate the API call
                     });
                 },
                   onError: (err) => {
                       console.error('PayPal Checkout onError', err);
                       alert('An error occurred during the transaction. Please try again.');
                   }
               }).render('#paypal-button-container');
           }
       };

       script.onload = loadPayPalButton;
       document.body.appendChild(script);

       return () => {
           if (document.body.contains(script)) {
               document.body.removeChild(script);
           }
           const container = document.getElementById("paypal-button-container");
           if (container) container.innerHTML = "";
       };
   }
}, [hasCarFax]);



return (
<section class="car-details">
   <SellerNav />
   <div class="container">
      <form id="multi-step-form" onSubmit={handleSubmit} enctype="multipart/form-data">
         <h3 class="main-heading text-center">Upload Vehicle Details</h3>
         {renderStepIndicator()}
         <div className={`form-step ${currentStep === 1 ? 'active' : ''}`} id="step-1" ref={el => stepRefs.current[0] = el}>
         <div class="vehicle-detaile-one">
            <h3 class="vehicle-info-heading">Tell us more about your vehicle</h3>
            <p>Share details about your vehicle to receive a solid offer within minutes</p>
            <div class="mileage-section d-flex">
               <div className="form-group">
                  <input type="text" value={location} hidden />
                  <input type="text" value={vin}  hidden/>
                  <input type="text" value={year}  hidden/>
                  <input type="text" value={make}  hidden/>
                  <input type="text" value={model} hidden />
                  <input type="text" value={trim} hidden />
                  <input type="text" value={mileage}  hidden/>
                  <label htmlFor="exampleFormControlSelect1">Color</label>
                  <select className="form-control" id="exampleFormControlSelect1" value={selectedColor} onChange={handleColorChange}>
                     <option value="Red">Red</option>
                     <option value="Orange">Orange</option>
                     <option value="Yellow">Yellow</option>
                     <option value="Green">Green</option>
                     <option value="Blue">Blue</option>
                     <option value="Pink">Pink</option>
                     <option value="Purple">Purple</option>
                     <option value="Indigo">Indigo</option>
                     <option value="Violet">Violet</option>
                     <option value="Brown">Brown</option>
                     <option value="Gray">Gray</option>
                     <option value="Black">Black</option>
                     <option value="White">White</option>
                  </select>
               </div>
               <div class="form-group">
                  <label for="exampleFormControlSelect1">Keys</label>
                  <select class="form-control" id="exampleFormControlSelect1" value={selectedKeys} onChange={handlekeys} >
                     <option value="1">1</option>
                     <option value="2">2</option>
                     <option value="3">3</option>
                  </select>
               </div>
            </div>
            <div class="vehicle-detail-list">
               <div class="row">
                  <div class="col-md-4">
                     <div className="form-check">
                        <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck1"
                        checked={setoftire} // Bind checked status to state variable
                        onChange={handleSetoftireChange} // Handle checkbox change
                        />
                        <label className="form-check-label" htmlFor="defaultCheck1"></label>
                        <span>2 sets of tire</span>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div className="form-check">
                        <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck2"
                        checked={windowTint} // Bind checked status to state variable
                        onChange={handleWindowTintChange} // Handle checkbox change
                        />
                        <label className="form-check-label" htmlFor="defaultCheck2"></label>
                        <span>Window tint</span>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="form-check">
                        <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck3"
                        checked={aftermarketExhaust} // Bind checked status to state variable
                        onChange={handleAftermarketExhaustChange} // Handle checkbox change
                        />
                        <label class="form-check-label" for="defaultCheck3"></label>
                        <span>Aftermarket exhaust</span>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="form-check">
                        <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck4"
                        checked={aftermarketRims} // Bind checked status to state variable
                        onChange={handleAftermarketRimsChange} // Handle checkbox change
                        />
                        <label class="form-check-label" for="defaultCheck4"></label>
                        <span>Aftermarket rims</span>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="form-check">
                        <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck5"
                        checked={roofRack} // Bind checked status to state variable
                        onChange={handleRoofRackChange} // Handle checkbox change
                        />
                        <label class="form-check-label" for="defaultCheck5"></label>
                        <span>Roof rack</span>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="form-check">
                        <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck6"
                        checked={remoteStarter} // Bind checked status to state variable
                        onChange={handleRemoteStarterChange} // Handle checkbox change
                        />
                        <label class="form-check-label" for="defaultCheck6"></label>
                        <span>Remote starter</span>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="form-check">
                        <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck7"
                        checked={aftermarketStereo} // Bind checked status to state variable
                        onChange={handleAftermarketStereoChange} // Handle checkbox change
                        />
                        <label class="form-check-label" for="defaultCheck7"></label>
                        <span>Aftermarket stereo</span>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="form-check">
                        <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck8"
                        checked={aftermarketSpoiler} // Bind checked status to state variable
                        onChange={handleAftermarketSpoilerChange} // Handle checkbox change
                        />
                        <label class="form-check-label" for="defaultCheck8"></label>
                        <span>Aftermarket spoiler</span>
                     </div>
                  </div>
               </div>
               <h3 class="vehicle-info-heading pt-5">Any exterior/body damage?</h3>
               <div class="row">
                  <div class="col-md-12">
                     <div class="form-check">
                        <input
                        className="form-check-input"
                        type="checkbox"
                        id="defaultCheck9"
                        checked={minorDamage} // Bind checked status to state variable
                        onChange={handleMinorDamageChange} // Handle checkbox change
                        />
                        <label class="form-check-label" for="defaultCheck9"></label>
                        <span>Minor damage (scratches, stone chips,scruffs)</span>
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck10"
                        checked={fadingPaints} // Bind checked status to state variable
                        onChange={handleFadingPaintsChange} // Hand
                        />
                        <label class="form-check-label" for="defaultCheck10"></label>
                        <span>Fading paints</span>
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck11"
                        checked={rust} // Bind checked status to state variable
                        onChange={handleRustChange} // Handle checkbox change
                        />
                        <label class="form-check-label" for="defaultCheck11"></label>
                        <span>Rust</span>
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck12"
                        checked={hailDamage} // Bind checked status to state variable
                        onChange={handleHailDamageChange} // Handle checkbox change
                        />
                        <label class="form-check-label" for="defaultCheck12"></label>
                        <span>hail damage</span>
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck13"
                        checked={mintCondition} // Bind checked status to state variable
                        onChange={handleMintConditionChange} // Handle checkbox change
                        />
                        <label class="form-check-label" for="defaultCheck13"></label>
                        <span>mint condition</span>
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck14"
                        checked={dents} // Bind checked status to state variable
                        onChange={handleDentsChange} // Handle checkbox change
                        />
                        <label class="form-check-label" for="defaultCheck14"></label>
                        <span>dents</span>
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck30"
                        checked={other} // Bind checked status to state variable
                        onChange={handleOtherChange} // Handle checkbox change
                        />
                        <label class="form-check-label" for="defaultCheck30"></label>
                        <span>others</span>
                     </div>
                  </div>
                  {other && (
                  <div class="col-md-12">
                     <div className="form-group mt-2">
                        <div className="form-group pb-3 px-2 " id="inputField4">                
                           <textarea className="form-control" id="exampleFormControlTextarea30" rows="3" placeholder="Please explain"
                              value={noteother}
                              onChange={handleotherlenote}
                              ></textarea>
                        </div>
                     </div>
                  </div>
                  )}
               </div>
               <h3 class="vehicle-info-heading pt-5">Any interior damage?</h3>
               <div class="row">
                  <div class="col-md-6">
                     <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck15" 
                        checked={ripsOrTears} // Bind checked status to state variable
                        onChange={handleRipsOrTearsChange} // Handle checkbox change
                        />
                        <label class="form-check-label" for="defaultCheck15"></label>
                        <span>Rips or tears on the seats</span>
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck16"
                        checked={visibleStain} // Bind checked status to state variable
                        onChange={handleVisibleStainChange} // Handle checkbox change
                        />
                        <label class="form-check-label" for="defaultCheck16"></label>
                        <span>Visible stain on the seats</span>
                     </div>
                  </div>
                  <div class="col-md-12">
                     <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck17"
                        checked={strongSmell} // Bind checked status to state variable
                        onChange={handleStrongSmellChange} // Handle checkbox change
                        />
                        <label class="form-check-label" for="defaultCheck17"></label>
                        <span>strong smell (smoking, pets and other)</span>
                     </div>
                  </div>
                  <div class="col-md-12">
                     <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck18"
                        checked={damagedSystems} // Bind checked status to state variable
                        onChange={handleDamagedSystemsChange} // Handle checkbox change
                        />
                        <label class="form-check-label" for="defaultCheck18"></label>
                        <span>damaged systems  (navigation, entertainment, and controls) others</span>
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck19"
                        checked={IntmintCondition} // Bind checked status to state variable
                        onChange={handleIntMintConditionChange} // Handle checkbox change
                        />
                        <label class="form-check-label" for="defaultCheck19"></label>
                        <span>mint condition</span>
                     </div>
                  </div>
                  <div class="col-md-6">
                     <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="defaultCheck31"
                        checked={otherint} // Bind checked status to state variable
                        onChange={handleOtherIntChange} // Handle checkbox change
                        />
                        <label class="form-check-label" for="defaultCheck31"></label>
                        <span>others</span>
                     </div>
                  </div>
                  {otherint && (
                  <div class="col-md-12">
                     <div className="form-group mt-2">
                        <div className="form-group pb-3 px-2 " id="defaultCheck31">                
                           <textarea className="form-control" id="exampleFormControlTextarea31" rows="3" placeholder="Please explain"
                              value={noteIntother}
                              onChange={handleotherleIntnote}
                              ></textarea>
                        </div>
                     </div>
                  </div>
                  )}
               </div>
            </div>
            <div class="our-priority pt-5">
               <h3 class="vehicle-info-heading">Our top priority is to sell your car.</h3>
               <p>We are ager to have a conversation with you.</p>
               <div className="our-priority-list">
                  <p className="pb-0 mb-0">Do you smoke in this vehicle?</p>
                  <div className="list-panel-priority">
                     <div className="form-check">
                        <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios1"
                        id="exampleRadios1"
                        value="No"
                        checked={!smokeInVehicle} // Bind checked status to state variable
                        onChange={handleSmokeInVehicleChange} // Handle radio button change
                        />
                        <label className="form-check-label" htmlFor="exampleRadios1"><span>No</span> </label>
                     </div>
                     <div className="form-check">
                        <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios1"
                        id="exampleRadios2"
                        value="Yes"
                        checked={smokeInVehicle} // Bind checked status to state variable
                        onChange={handleSmokeInVehicleChange} // Handle radio button change
                        />
                        <label className="form-check-label" htmlFor="exampleRadios2"><span>Yes</span> </label>
                     </div>
                  </div>
               </div>
               <div className="our-priority-list">
                  <p className="pb-0 mb-0">Do you have original factory rims?</p>
                  <div className="list-panel-priority">
                     <div className="form-check">
                        <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios2"
                        id="exampleRadios3"
                        value="No"
                        checked={!hasOriginalRims} // Bind checked status to state variable
                        onChange={handleOriginalRimsChange} // Handle radio button change
                        />
                        <label className="form-check-label" htmlFor="exampleRadios3"><span>No</span> </label>
                     </div>
                     <div className="form-check">
                        <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios2"
                        id="exampleRadios4"
                        value="Yes"
                        checked={hasOriginalRims} // Bind checked status to state variable
                        onChange={handleOriginalRimsChange} // Handle radio button change
                        />
                        <label className="form-check-label" htmlFor="exampleRadios4"><span>Yes</span> </label>
                     </div>
                  </div>
               </div>
               <div className="our-priority-list">
                  <p className="pb-0 mb-0">Have you replaced your tires in the last 12 months?</p>
                  <div className="list-panel-priority">
                     <div className="form-check">
                        <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios3"
                        id="exampleRadios5"
                        value="No"
                        checked={!tireReplacement} // Bind checked status to state variable
                        onChange={handleTireReplacementChange} // Handle radio button change
                        />
                        <label className="form-check-label" htmlFor="exampleRadios5"><span>No</span> </label>
                     </div>
                     <div className="form-check">
                        <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios3"
                        id="exampleRadios6"
                        value="Yes"
                        checked={tireReplacement} // Bind checked status to state variable
                        onChange={handleTireReplacementChange} // Handle radio button change
                        />
                        <label className="form-check-label" htmlFor="exampleRadios6"><span>Yes</span> </label>
                     </div>
                  </div>
               </div>
               <div>
                  <div className="our-priority-list">
                     <p className="pb-0 mb-0">Is your vehicle drivable?</p>
                     <div className="list-panel-priority">
                        <div className="form-check">
                           <input
                              className="form-check-input"
                              type="radio"
                              name="exampleRadios4"
                              id="exampleRadios7"
                              value="No"
                              checked={!vehicleDrivable}
                              onChange={handleVehicleDrivableChange}
                              />
                           <label className="form-check-label" htmlFor="exampleRadios7"><span>No</span></label>
                        </div>
                        <div className="form-check">
                           <input
                              className="form-check-input"
                              type="radio"
                              name="exampleRadios4"
                              id="exampleRadios8"
                              value="Yes"
                              checked={vehicleDrivable}
                              onChange={handleVehicleDrivableChange}
                              />
                           <label className="form-check-label" htmlFor="exampleRadios8"><span>Yes</span></label>
                        </div>
                     </div>
                  </div>
                  {!vehicleDrivable && (
                  <div className="form-group pb-3 px-2 " id="inputField4">                
                     <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Please explain why"
                        value={notedrivable}
                        onChange={handledrivablenote}
                        ></textarea>
                  </div>
                  )}
               </div>
               <div className="our-priority-list">
                  <p className="pb-0 mb-0">Any crack on the windshield?</p>
                  <div className="list-panel-priority">
                     <div className="form-check">
                        <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios5"
                        id="exampleRadios9"
                        value="No"
                        checked={!crackOnWindshield} // Bind checked status to state variable
                        onChange={handleCrackOnWindshieldChange} // Handle radio button change
                        />
                        <label className="form-check-label" htmlFor="exampleRadios9"><span>No</span> </label>
                     </div>
                     <div className="form-check">
                        <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios5"
                        id="exampleRadios10"
                        value="Yes"
                        checked={crackOnWindshield} // Bind checked status to state variable
                        onChange={handleCrackOnWindshieldChange} // Handle radio button change
                        />
                        <label className="form-check-label" htmlFor="exampleRadios10"><span>Yes</span> </label>
                     </div>
                  </div>
               </div>
               <div className="our-priority-list">
                  <p className="pb-0 mb-0">Do you have any extended warranty?</p>
                  <div className="list-panel-priority">
                     <div className="form-check">
                        <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios6"
                        id="exampleRadios11"
                        value="No"
                        checked={!extendedWarranty} // Bind checked status to state variable
                        onChange={handleExtendedWarrantyChange} // Handle radio button change
                        />
                        <label className="form-check-label" htmlFor="exampleRadios11"><span>No</span> </label>
                     </div>
                     <div className="form-check">
                        <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios6"
                        id="exampleRadios12"
                        value="Yes"
                        checked={extendedWarranty} // Bind checked status to state variable
                        onChange={handleExtendedWarrantyChange} // Handle radio button change
                        />
                        <label className="form-check-label" htmlFor="exampleRadios12"><span>Yes</span> </label>
                     </div>
                  </div>
               </div>
             {/*  <div className="our-priority-list">
                  <p className="pb-0 mb-0">Are you interested in a trade-in?</p>
                  <div className="list-panel-priority">
                     <div className="form-check">
                        <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios7"
                        id="exampleRadios13"
                        value="No"
                        checked={!tradeInInterest} // Bind checked status to state variable
                        onChange={handleTradeInInterestChange} // Handle radio button change
                        />
                        <label className="form-check-label" htmlFor="exampleRadios13"><span>No</span> </label>
                     </div>
                     <div className="form-check">
                        <input
                        className="form-check-input"
                        type="radio"
                        name="exampleRadios7"
                        id="exampleRadios14"
                        value="Yes"
                        checked={tradeInInterest} // Bind checked status to state variable
                        onChange={handleTradeInInterestChange} // Handle radio button change
                        />
                        <label className="form-check-label" htmlFor="exampleRadios14"><span>Yes</span> </label>
                     </div>
                  </div>
               </div> */}
               <div className="our-priority-list"> 
  <p className="pb-0 mb-0">Automatic or manual transmission?</p>
  <div className="list-panel-priority right-spacetab">
    <div className="form-check">
      <input
        className="form-check-input"
        type="radio"
        name="transmission"
        id="exampleRadios13"
        value="Automatic" // Now it is set to "Automatic"
        checked={transmission === 'Automatic'} // Check if the state is "Automatic"
        onChange={handleTransmissionChange} // Handle change
      />
      <label className="form-check-label" htmlFor="exampleRadios13">
        <span>Automatic</span>
      </label>
    </div>
    <div className="form-check">
      <input
        className="form-check-input"
        type="radio"
        name="transmission"
        id="exampleRadios14"
        value="Manual" // Set value to "Manual"
        checked={transmission === 'Manual'} // Check if the state is "Manual"
        onChange={handleTransmissionChange} // Handle change
      />
      <label className="form-check-label" htmlFor="exampleRadios14">
        <span>Manual</span>
      </label>
    </div>
  </div>
</div>
            </div>
         </div>
         <button class="btn btn-primary form-button py-3 px-5 my-3" type="button"onClick={nextStep}>Continue</button>
   </div>
   <div className={`form-step ${currentStep === 2 ? 'active' : ''}`} id="step-2" ref={el => stepRefs.current[1] = el}>
   <div class="vehicle-detaile-one">
   <div className="readyto-sell">
   <p className="pb-0 mb-0">Select the best option that describes the condition of the car</p>
   <div className="readyto-sell-priority">
   <div className="row">
   <div className="col-lg-3 col-6">
   <div className="form-check">
   <input
   className="form-check-input"
   type="radio"
   name="exampleRadios10"
   id="exampleRadios01"
   value="Awesome"
   checked={carCondition === 'Awesome'} // Bind checked status to state variable
   onChange={handleCarConditionChange} // Handle radio button change
   />
   <label className="form-check-label" htmlFor="exampleRadios01"><span>Awesome</span> </label>
   </div>
   </div>
   <div className="col-lg-3 col-6">
   <div className="form-check">
   <input
   className="form-check-input"
   type="radio"
   name="exampleRadios10"
   id="exampleRadios02"
   value="Good"
   checked={carCondition === 'Good'} // Bind checked status to state variable
   onChange={handleCarConditionChange} // Handle radio button change
   />
   <label className="form-check-label" htmlFor="exampleRadios02"><span>Good</span> </label>
   </div>
   </div>
   <div className="col-lg-3 col-6">
   <div className="form-check">
   <input
   className="form-check-input"
   type="radio"
   name="exampleRadios10"
   id="exampleRadios03"
   value="Ok"
   checked={carCondition === 'Ok'} // Bind checked status to state variable
   onChange={handleCarConditionChange} // Handle radio button change
   />
   <label className="form-check-label" htmlFor="exampleRadios03"><span>Ok</span> </label>
   </div>
   </div>
   <div className="col-lg-3 col-6">
   <div className="form-check">
   <input
   className="form-check-input"
   type="radio"
   name="exampleRadios10"
   id="exampleRadios04"
   value="Rough"
   checked={carCondition === 'Rough'} // Bind checked status to state variable
   onChange={handleCarConditionChange} // Handle radio button change
   />
   <label className="form-check-label" htmlFor="exampleRadios04"><span>Rough</span> </label>
   </div>
   </div>
   </div>
   </div>
   </div>
   <div className="readyto-sell features">
      <p className="pb-0 mb-0">Additional car features</p>
      <div className="readyto-sell-priority">
        <div className="row">
          {[
            "LEATHER SEAT",
            "HEATED SEAT",
            "HEATED STEERING WHEEL",
            "NAVIGATION",
            "ALLOY RIMS",
            "BLUETOOTH",
            "CARPLAY",
            "REVERSE CAMERA",
            "SUNROOF",
            "PANORAMIC ROOF",
            "POWER SEATS",

          ].map((feature, index) => (
            <div className="col-lg-3 col-6" key={index}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={feature}
                  id={`feature-${index}`}
                  checked={selectedFeatures.includes(feature)} // Show previously selected features
                  onChange={handleFeatureChange} // Handle checkbox change
                />
                <label className="form-check-label" htmlFor={`feature-${index}`}>
                  <span>{feature}</span>
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
      </div>
   <div class="ourview-listpanel my-3">
   <div class="our-view-list">
   <p class="pb-0 mb-0">Any accident claims? How much?</p>
   <div class="list-panel-priority">
   <div class="form-check">
   <input class="form-check-input" type="radio" name="exampleRadios" id="hideButton1"
      checked={!isInputVisible} 
      value="No"
      onChange={handleaccidentclaims} // Handle radio button change
      />
   <label class="form-check-label" for="exampleRadios11"><span>No</span> </label>                  
   </div>
   <div class="form-check">
   <input class="form-check-input" type="radio" name="exampleRadios" id="showButton1" value="Yes"
      checked={isInputVisible}
      onChange={handleaccidentclaims} // Handle radio button change
      />
   <label class="form-check-label" for="exampleRadios12"><span>Yes</span> </label>                 
   </div>
   </div>
   </div>
   {isInputVisible && (
   <div className="form-group pb-3 px-2" id="inputField1">                
   <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Eg. Front end Damage, rear end collision etc., $2000"
      value={claimnote}
      onChange={handleclaimnote}
      ></textarea>
   </div>
   )}
   </div>
   <div className="ourview-listpanel my-3">
   <div className="our-view-list">
   <p className="pb-0 mb-0">What rims are on the car?</p>
   <div className="list-panel-priority">
   <div className="form-check option-field">
   <input
      className="form-check-input"
      type="radio"
      name="exampleRadiosRims"
      id="hideButton"
      value="StockRims"
      onChange={handleOptionChangerimss}
      checked={!isAfterMarketSelected}
      />
   <label className="form-check-label" htmlFor="exampleRadios133"><span>Stock Rims</span></label>
   </div>
   <div className="form-check option-field">
   <input
      className="form-check-input"
      type="radio"
      name="exampleRadiosRims"
      id="showButton"
      value="AfterMarket"
      onChange={handleOptionChangerimss}
      checked={isAfterMarketSelected}
      />
   <label className="form-check-label" htmlFor="exampleRadios12"><span>After Market</span></label>
   </div>
   </div>
   </div>
   </div>
   {isAfterMarketSelected && (
   <div className="ourview-listpanel  my-3" id="inputField">
   <div className="our-view-list">
   <p className="pb-0 mb-0">Do you have stock rims?</p>
   <div className="list-panel-priority">
   <div className="form-check">
   <input className="form-check-input" type="radio" name="exampleRadios13" id="exampleRadios13" value="No"
      onChange={handleMarketRimsChange} 
      checked={!rimschange} 
      />
   <label className="form-check-label" htmlFor="exampleRadios12"><span>No</span></label>
   </div>
   <div className="form-check">
   <input className="form-check-input" type="radio" name="exampleRadios13" id="exampleRadios14" value="Yes"
      onChange={handleMarketRimsChange} 
      checked={rimschange} 
      />
   <label className="form-check-label" htmlFor="exampleRadios13"><span>Yes</span></label>
   </div>
   </div>
   </div>
   </div>
   )}
   <div class="ourview-listpanel my-3">
   <div class="our-view-list">
   <p class="pb-0 mb-0">Any issues with the vehicle?</p>
   <div class="list-panel-priority">
   <div class="form-check">
   <input class="form-check-input" type="radio" name="exampleRadios25" id="hideButton2" value="No"
      onChange={handleIssuesChange}
      checked={!hasIssues}
      />
   <label class="form-check-label" for="exampleRadios16"><span>No</span> </label>                  
   </div>
   <div class="form-check">
   <input class="form-check-input" type="radio" name="exampleRadios25" id="showButton2" value="Yes"
      onChange={handleIssuesChange}
      checked={hasIssues}
      />
   <label class="form-check-label" for="exampleRadios16"><span>Yes</span> </label>                 
   </div>
   </div>
   </div>
   {hasIssues && (
   <div className="form-group pb-3 px-2" id="inputField2">
   <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="eg. Warning Light shows, Brakes are squeaking/grinding etc."
      value={issuenote}
      onChange={handleissuenote}
      ></textarea>
   </div>
   )}
   </div>
   <div class="ourview-listpanel my-3">
   <div class="our-view-list">
   <p class="pb-0 mb-0">Are there any modifications on the car?</p>
   <div class="list-panel-priority">
   <div class="form-check">
   <input class="form-check-input" type="radio" name="exampleRadios14" id="hideButton3" value="No"
      onChange={handleModificationsChange}
      checked={!isModificationsSelected}
      />
   <label class="form-check-label" for="exampleRadios17"><span>No</span> </label>                  
   </div>
   <div class="form-check">
   <input class="form-check-input" type="radio" name="exampleRadios14" id="showButton3" value="Yes"
      onChange={handleModificationsChange}
      checked={isModificationsSelected}
      />
   <label class="form-check-label" for="exampleRadios18"><span>Yes</span> </label>                 
   </div>
   </div>
   </div>
   {isModificationsSelected && (
   <div class="form-group pb-3 px-2 " id="inputField3">                
   <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Please describe"
      value={modificationsnote}
      onChange={handlemodificationsnote}
      ></textarea>
   </div>
   )}
   </div>
   <div class="ourview-listpanel my-3">
   <div class="our-view-list">
   <p class="pb-0 mb-0">Is the car leased or financed?</p>
   <div class="list-panel-priority">
   <div class="form-check">
   <input class="form-check-input" type="radio" name="exampleRadios15" id="hideButton4" value="No"  
      onChange={handlefinanced}
      checked={!isfinancedSelected}
      />
   <label class="form-check-label" for="exampleRadios19"><span>No</span> </label>                  
   </div>
   <div class="form-check">
   <input class="form-check-input" type="radio" name="exampleRadios15" id="showButton4" value="Yes"
      onChange={handlefinanced}
      checked={isfinancedSelected}
      />
   <label class="form-check-label" for="exampleRadios20"><span>Yes</span> </label>                 
   </div>
   </div>
   </div>
   {isfinancedSelected && (
   <div class="form-group pb-3 px-2 " id="inputField4">                
   <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Please share who the car is leased or financed by and the amount"
      value={financednote}
      onChange={handlefinancednote}
      ></textarea>
   </div>
   
   )}
  
   </div>
   
   <div>

   <div class="form-group pb-3 px-2 " id="inputField4">      
   <h3 class="vehicle-info-heading pt-5">Note</h3>
   <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Leaving a detailed description makes your car stand out."
      onChange={handlenote}
      ></textarea>
   </div>

  {/* Upload Video Option Always Visible */}
  <div>
  <h3 className="vehicle-info-heading pt-3">Upload Video</h3>
  <div class="upload-widget">
  <input type="file" accept="video/*" onChange={handleVideoChange} />
  </div>
</div>

  {/* Conditionally render the vehicle video if it exists */}
  {vechiclevideo && vechiclevideo !== "null" && (
    <>
      <h3 className="vehicle-info-heading pt-3">Vehicle Video</h3>
      <div id="vechiclevideo">
         <video
           width="530"
           height="315"
           controls
           preload="metadata"
         >
           <source
             src={`https://backend.carchaser.ca/videos/${storedVehicleId}/${vechiclevideo}`}
             type="video/mp4"
           />
           <source
             src={`https://backend.carchaser.ca/videos/${storedVehicleId}/${vechiclevideo}`}
             type="video/quicktime"
           />
           Your browser does not support the video tag.
         </video>
     </div>
    </>
  )}
</div>
<h3 className="vehicle-info-heading pt-3">Exterior of the car</h3>
<div className="row">
  {/* Display existing images */}
  {exteriorImageUrls.map((imageUrl, index) => (
    <div key={index}>
      {imageUrl ? (
        <div style={{ position: 'relative' }}>
          {/* Show uploaded image as avatar */}
          <img
            src={imageUrl}
            alt={`Exterior Image ${index}`}
            style={{
              width: '200px',
              height: '150px',
              border: '1px solid #ccc',
              margin: '5px',
              cursor: 'pointer',
              objectFit: 'cover',  // Ensure the image fits well inside the avatar
            }}
          />
          <button
            onClick={() => handleRemoveExteriorImage(index)}
            style={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
              color: 'red',
              fontSize: '16px',
            }}
          >
            &#10006;
          </button>
        </div>
      ) : (
        <div
          onClick={() => document.getElementById(`imageInpute-${index}`).click()}
          style={{
            width: '200px',
            height: '150px',
            margin: '5px',
            border: '1px solid #ccc',
            textAlign: 'center',
            cursor: 'pointer',
            background: `url(${
               index === 0 ? '../images/exterior-front.jpg' :
               index === 1 ? '../images/driver_side.jpg' :
               index === 2 ? '../images/passenger_side.jpg' :
               '../images/exterior-rear.jpg'
             })`, // Conditional default image based on index
            backgroundSize: '100px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'flex-end',
            fontWeight: '600',
            padding: '0.5rem',
            justifyContent: 'center'
          }}
          
          
        >
          {/* Optionally show labels for different slots */}
          {index === 0 && "Front"}
          {index === 1 && "Driver side"}
          {index === 2 && "Passenger side"}
          {index === 3 && "Rear"}
        </div>
      )}
      <input
        id={`imageInpute-${index}`}
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={(event) => handleImageChangeexterior(event, index)}
      />
    </div>
  ))}
</div>
   <h3 className="vehicle-info-heading pt-3">Interior of the car</h3>
   <div className="row">
   {/* Render input fields for each type of car part */}
   {/* Display existing images */}
   {interiorImageUrls.map((imageUrl, index) => (
    <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {/* Change titles based on the index */}
        {imageUrl ? (
            <div style={{ position: 'relative' }}>
                <img
                    src={imageUrl}
                    alt={`Interior Image ${index}`}
                    style={{ width: '200px', height: '150px', margin: '5px', cursor: 'pointer',  border: '1px solid #ccc', }}
                />
                <button
                    onClick={() => handleRemoveImage(index)}
                    style={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        background: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                        color: 'red',
                        fontSize: '16px',
                    }}
                >
                    &#10006;
                </button>
            </div>
        ) : (
            <div
                onClick={() => document.getElementById(`imageInput-${index}`).click()}
                style={{
                  width: '200px',
                  height: '150px',
                  margin: '5px',
                  border: '1px solid #ccc',
                  textAlign: 'center',
                  cursor: 'pointer',
                  background: `url(${
                     index === 0 ? '../images/dashboard.jpg' :
                     index === 1 ? '../images/console.jpg' :
                     index === 2 ? '../images/steering.jpg' :
                     '../images/back_seat.jpg'
                   })`, // Conditional default image based on index
                  backgroundSize: '100px',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  borderRadius: '5px',
                  display: 'flex',
                  alignItems: 'flex-end',
                  fontWeight: '600',
                  padding: '0.5rem',
                  justifyContent: 'center'
                }}
            >
              
            {index === 0 && "Dashboard"}
            {index === 1 && "Console"}
            {index === 2 && "showing steering"}
            {index === 3 && "Back seat"}
        
            </div>
        )}

        <input
            id={`imageInput-${index}`}
            type="file"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={(event) => handleImageChange(event, index)}
        />
    </div>
))}   
   </div>
   
   <h3 className="vehicle-info-heading pt-3">
  Damage Photos
  {message && <span style={{ color: 'red' }}> ({message})</span>}
</h3>

<div className="row">
  {dashboardImageUrls.map((imageUrl, index) => (
    <div key={index}>
      {imageUrl ? (
        <div style={{ position: 'relative' }}>
          <img
            src={imageUrl}
            alt={`Dashboard Image ${index}`}
            style={{
              width: '200px',
              height: '150px',
              margin: '5px',
              cursor: 'pointer',
              border: '1px solid #ccc',
            }}
          />
          <button
            onClick={() => handleRemoveDashboardImage(index)}
            style={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
              color: 'red',
              fontSize: '16px',
            }}
          >
            &#10006;
          </button>

          {/* Only show radio buttons for uploaded images */}
          <div className="catagory-option" style={{ marginTop: '10px' }}>
            <div className="select-option">
              <input
                type="radio"
                id={`exterior-${index}`}
                name={`imageType-${index}`}
                value="Exterior"
                checked={imageTypes[index] === 'Exterior'}
                onChange={() => handleRadioChange(index, 'Exterior')}
                required={imageUrl ? true : false} // Only required for uploaded images
              />
              <label htmlFor={`exterior-${index}`}>Exterior</label>
            </div>
            <div className="select-option">
              <input
                type="radio"
                id={`interior-${index}`}
                name={`imageType-${index}`}
                value="Interior"
                checked={imageTypes[index] === 'Interior'}
                onChange={() => handleRadioChange(index, 'Interior')}
                required={imageUrl ? true : false} // Only required for uploaded images
              />
              <label htmlFor={`interior-${index}`}>Interior</label>
            </div>
          </div>
        </div>
      ) : (
        <div
          onClick={() => document.getElementById(`imageInputd-${index}`).click()}
          style={{
            width: '200px',
            height: '150px',
            margin: '5px',
            border: '1px solid #ccc',
            textAlign: 'center',
            cursor: 'pointer',
            background: `url(${
              index === 0
                ? '../images/demage.jpg'
                : index === 1
                ? '../images/demage.jpg'
                : index === 2
                ? '../images/demage.jpg'
                : '../images/demage.jpg'
            })`,
            backgroundSize: '100px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'flex-end',
            fontWeight: '600',
            padding: '0.5rem',
            justifyContent: 'center',
          }}
        >
          Add Image
        </div>
      )}
      <input
        id={`imageInputd-${index}`}
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={(event) => handleImageChangedashboard(event, index)}
      />
    </div>
  ))}
</div>
   <h3 className="vehicle-info-heading pt-3">Rims of the car</h3>
   <div className="row">
   {/* Display existing images */}
   {rimsImageUrls.map((imageUrl, index) => (
   <div key={index}>
   <label htmlFor={`imageInputr-${index}`}>
   {imageUrl ? (
   <div style={{ position: 'relative' }}>
   <img
   src={imageUrl}
   alt={`Rims Image ${index}`}
   style={{ width: '200px', height: '150px', margin: '5px', cursor: 'pointer', border: '1px solid #ccc', }}
   />
   <button
      onClick={() => handleRemoveRimsImage(index)}
   style={{
   position: 'absolute',
   top: '5px',
   right: '5px',
   background: 'transparent',
   border: 'none',
   cursor: 'pointer',
   color: 'red',
   fontSize: '16px',
   }}
   >
   &#10006;
   </button>
   </div>
   ) : (
   <div
   style={{
      width: '200px',
      height: '150px',
      margin: '5px',
      border: '1px solid #ccc',
      textAlign: 'center',
      cursor: 'pointer',
      background: `url(${
         index === 0 ? '../images/front_left.jpg' :
         index === 1 ? '../images/front_right.jpg' :
         index === 2 ? '../images/back_left.jpg' :
         '../images/back_right.jpg'
       })`, // Conditional default image based on index
      backgroundSize: '100px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      borderRadius: '5px',
      display: 'flex',
      alignItems: 'flex-end',
      fontWeight: '600',
      padding: '0.5rem',
      justifyContent: 'center'
    }}
   >
            {index === 0 && "Front left"}
            {index === 1 && "Front right"}
            {index === 2 && "Back left"}
            {index === 3 && "Back right"}
   </div>
   )}
   <input
   id={`imageInputr-${index}`}
   type="file"
   accept="image/*"
   style={{ display: 'none' }}
   onChange={(event) => handleImageChangerims(event, index)}
   />
   </label>
   </div>
   ))}
   </div>
   </div>
   <div className="row">
    <h3 className="vehicle-info-heading pt-3">Upload Ownership & Driving Licence</h3>
    <div className="container doc-upload-panel">
      <div class="row">
    {/* Input for Ownership */}
    <div className="col-md-6 mb-4">
    <label htmlFor="ownershipUpload">
      Upload Ownership (Image or PDF):
      <span style={{ color: 'red' }}>*</span>
    </label>
    <div className="upload-widget">
      <input
        type="file"
        accept="image/*,application/pdf"
        onChange={handleOwnershipChange}
      />
    </div>

    {/* Display selected file name if available */}
    {ownershipFile && typeof ownershipFile !== 'string' && (
      <p>Selected Ownership File: {ownershipFile.name}</p>
    )}

    {/* Display preview image conditionally */}
    <div class="document-attachment">
    <img
      src={
        ownershipFile
          ? typeof ownershipFile === 'string'
            ? `https://backend.carchaser.ca/uploads/${storedVehicleId}/${ownershipFile}`
            : URL.createObjectURL(ownershipFile)
          : ''
      }
      
    />
    </div>
  </div>

    {/* Input for Driving License */}
    <div className="col-md-6 mb-4">
      <label htmlFor="drivingLicenseUpload">Upload Driving License (Image or PDF):<span style={{ color: 'red' }}>*</span></label>
      <div class="upload-widget">
      <input type="file" accept="image/*,application/pdf" onChange={handleDrivingLicenseChange} />
      </div>
      {drivingLicenseFile && typeof drivingLicenseFile !== 'string' && (
      <p>Selected Driving License File: {drivingLicenseFile.name}</p>
    )}
      <div class="document-attachment">
      <img
      src={
        drivingLicenseFile
          ? typeof drivingLicenseFile === 'string'
            ? `https://backend.carchaser.ca/uploads/${storedVehicleId}/${drivingLicenseFile}`
            : URL.createObjectURL(drivingLicenseFile)
          : ''
      }
    />
       </div>
    </div>
    </div>
    </div>
  </div>
   {isNewUser ? (
   <div class="evalution-report">
   <h3 class="vehicle-info-heading pt-3"> Do you have a Carfax Evaluation Report?</h3>
   <button class="btn btn-primary mr-2" type="button" onClick={() => setHasCarFax(true)}>Yes</button>
   <button class="btn btn-primary" type="button" onClick={() => setHasCarFax(false)}>No</button>
  {/* Conditionally render the file upload option if the seller has a report */}
  {(hasCarFax === true) && (
                <div class="mt-3 upload-carfex p-3">
                    <h3 class="vehicle-info-heading">Upload your Carfax Evaluation Report (PDF only):</h3>
                    <input 
                        type="file" 
                        accept="application/pdf" 
                        onChange={handleFileChangeCar} 
                    />
                </div>
            )}
            {hasCarFax === false && (
                     <div class="mt-3">
                      <label>
  <b style={{ color: 'red' }}>Note:</b> A CARFAX report is essential for building trust with potential dealers interested in buying your car. For a 
  <b style={{ color: 'red' }}> $65 fee,</b> you’ll receive verified details about your car’s history, including accident reports and any liens. 
  By adding a CARFAX report, you provide dealers with the information they need to make an informed decision, increasing your chances of a successful sale.
</label>
                           </div>
                     )}
{hasCarFax === false && !transactionComplete && (

                <div id="paypal-button-container" class="mt-4"></div>
            )}

   <div>
        {pdfDownloadStatus && pdfFilename && (
            <a
                href={`https://backend.carchaser.ca/uploads/${storedVehicleId}/${pdfFilename}`}
                download
                className="download-button"
                target="_blank"
            >
                <i className="icon-download" /> Download Carfax Report
            </a>
        )}
     <div class="message">
   <p class="text-success">{messagecarfaxreport}</p>
   </div>
    </div>
   <div className="form-group form-check banner-checkbox">
   <input 
      type="checkbox" 
      className="form-check-input" 
      id="exampleCheck6" 
      checked={isChecked} 
      onChange={handleCheckboxChange} 
      />
   <label className="form-check-label" htmlFor="exampleCheck6">
   By Signing up you agree to our <a href="" className="homebanner-from">Term and condition</a> and <a href="" className="homebanner-from">Privacy policy</a>.
   </label>
   </div>
   {isLoading && (
   <div className="loader">
   <img src="../images/load.png"/>
   <p>Loading your Estimate</p>
   </div>
   )}
   {!isLoading && (
  <button 
  className="btn btn-primary form-button py-3 px-5 my-3" 
  onClick={handleSubmit} 
  disabled={!isChecked}
  type="button"  
   // Button is enabled only if all conditions are true
>
  Submit
</button>
   )}
   </div>
   ) : (
   <div>{successMessage && <p class="text-success">{successMessage}</p>}
   <button className="btn btn-primary form-button py-3 px-5 my-3" onClick={handleSubmit} type="button">
   Update
   </button>
   </div>
   )}
   </div>
   </form>
   </div>

   {isPopupVisible && (
   <div class="main-popout">
      <div class="inner-popout">
         <div className="form-step-img">
            <img src="../images/pngkey-1.png" alt="image" />
         </div>
         <h3 className="main-heading py-3">Vehicle Added Successfully</h3>
         <a href="/carvalue" className="btn btn-primary px-5 py-3">Let's Get Going</a>
      </div>
   </div>
   )}
</section>
);
};