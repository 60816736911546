import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SellerNav } from './SellerNav';
import { useParams } from "react-router-dom"; // import useParams
import api from '../api';
export const BidHistory = () => {
  const { Vehicle_Id } = useParams(); // Get the ID from the URL slug
  const [bids, setBids] = useState([]); // State to store bid history
  const [topBids, setTopBids] = useState([]);
  const [vehicleBids, setVehicleBids] = useState([]);
  const [totalBids, setTotalBids] = useState(0);
  console.log(Vehicle_Id)
  const fetchBidHistory = async () => {
    try {
      const response = await api.get(`/bidhistory/${Vehicle_Id}`);
      const data = response.data;
      setTopBids(data.TopBids); // Set TopBids
      setVehicleBids(data.VehicleBids); // Set VehicleBids
      setTotalBids(data.TotalBids); // Set TotalBids
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching bid history:', error);
    }
  };



  useEffect(() => {
    if (Vehicle_Id) {
      fetchBidHistory();
    }
  }, [Vehicle_Id]);
  return (
    <section class="car-details">
   <SellerNav />
   <div className="container">
      {/* Bidding History Section */}
      <div className="bidding-historyinfo mt-5">
        <h3 className="main-heading text-center">Bid History</h3>
        <div className="bid-historyinner mt-4">
          <div className="table-panel">
            <table className="table table-bordered mid-table">
        <thead>
          <tr>
            <th scope="col">Bid Amount</th>
            <th scope="col">Bid DATE-TIME</th>
          </tr>
        </thead>
        <tbody>
          {topBids.length > 0 ? (
            topBids.map((bid) => (
              <tr key={bid.Bid_Id}>
                <td>
                  ${parseFloat(bid.Bid_Amount).toLocaleString()}
                  <img
                    className="pl-2"
                    src="https://admin.carchaser.ca/images/logo.png"
                    alt="logo"
                  />
                </td>
                <td>{bid.Bid_Date}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2" className="text-center">
                No Top Bids Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
          </div>
        </div>
      </div>
    </div>
   </section>

  )
}
